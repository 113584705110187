const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
const msApiUrl = process.env.REACT_APP_MS_API_URL;
const cdnHost = process.env.REACT_APP_CDN_HOST;

const API = {
  auth: {
    login: `${process.env.REACT_APP_API_URL}/apptoken`,
    recover: `${baseUrl}/customeradmin/sendrecoverypassword?Email=`,
    confirm: `${baseUrl}/customeradmin/recoverypassword`,
  },
  profile: {
    load: `${baseUrl}/profile/load`,
  },
  globals: {
    packageType: `${msApiUrl}/packages/parametrics/packageType`,
    packageSize: `${msApiUrl}/packages/parametrics/packageSize`,
    packageStatus: `${msApiUrl}/packages/parametrics/packageStatus`,
    deliveryTerm: `${msApiUrl}/packages/parametrics/deliveryTerm`,
    notifierType: `${msApiUrl}/mkp/parametrics/notifierType`,
    countries: `${baseUrl}/geolocation/loadcountries`,
    cities: `${baseUrl}/geolocation/loadcities`,
    roles: `${baseUrl}/apptype/userrole`,
    logisticOperator: `${msApiUrl}/opl/logisticoperator?page=0&size=1000`,
    userType: `${baseUrl}/apptype/usertype`,
    daysOfWeek: `${msApiUrl}/pqpoint/paquerypoint/daysOfWeek`,
    currentServices: `${msApiUrl}/pqpoint/paquerypoint/currentServices`,
  },
  marketplaces: {
    get: `${msApiUrl}/mkp/marketplace`,
  },
  massive: {
    list: `${msApiUrl}/packages/massive`,
    packagesUpload: (userId) =>
      `${msApiUrl}/caronte/integration/${userId}/massive`,
    packagesDownload: `${cdnHost}/assets/bulk-import.xlsx`,
  },
  packages: {
    get: `${msApiUrl}/packages/packages`,
    getById: `${msApiUrl}/packages/packages/`,
    history: `${msApiUrl}/packages/packages/history`,
    send: `${msApiUrl}/packages/packages`,
    getByExternalCode: `${msApiUrl}/packages/packages/findByExternalCode/`,
    withoutZone: `${msApiUrl}/packages/packages/withoutzone`,
    label: `${msApiUrl}/labels/packages`,
    labelByMassive: (code) => `${msApiUrl}/labels/packages/massive/${code}`,
    getLabels: `${msApiUrl}/labels/packages/packagesLabels/`,
    getNormalByStoreId: (storeId) =>
      `${msApiUrl}/packages/packages/packagesByStoreId/${storeId}`,
    getHistoryByStoreId: (storeId) =>
      `${msApiUrl}/packages/packages/packagesHistoryByStoreId/${storeId}`,
    getByMassiveCode: `${msApiUrl}/packages/massive/packages/list`,
    downloadDeliveryNoteById: (id) =>
      `${msApiUrl}/labels/packages/remittance/${id}`,
  },
  stores: {
    get: `${msApiUrl}/mkp/store`,
    create: `${msApiUrl}/mkp/store`,
  },
  zones: {
    getAllByLogisticOperator: (id) =>
      `${msApiUrl}/zones/logistics_operator/${id}`,
    resolve: `${msApiUrl}/mediator/resolveZone`,
  },
  geocode: {
    get: 'https://autocomplete.geocoder.api.here.com/6.2/suggest.json?',
    w3w: `${baseUrl}/integration/getw3wbyaddress/?`,
    resolveZone: `${msApiUrl}/maps/zones/resolveZone`,
    findZone: (searchOrPostalCode) =>
      `${msApiUrl}/maps/zones/findZone?search=${searchOrPostalCode}`,
  },
  user: {
    create: `${baseUrl}/customeradmin/create`,
    getAll: `${baseUrl}/customeradmin/getall/`,
    get: `${baseUrl}/customeradmin/get?id=`,
    update: `${baseUrl}/customeradmin/update`,
    delete: `${baseUrl}/customeradmin/delete?id=`,
    changePassword: `${baseUrl}/customeradmin/sendrecoverypassword`,
  },
  paquer: {
    get: `${msApiUrl}/pqr/paquer/`,
  },
  reports: {
    packages: `${msApiUrl}/reports/report`,
    history: `${msApiUrl}/reports/report/history`,
    withoutZone: `${msApiUrl}/reports/report/withoutzone`,
    storePackages: `${msApiUrl}/reports/report/store`,
    storeHistoryPackages: `${msApiUrl}/reports/report/store/history`,
  },
};

export default API;
